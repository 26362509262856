import React from "react"
import Layout from "../components/layout"
import Bible from "../components/bible"

export default () => 
    <Layout>
        <h1>How to Become A Christian</h1>

        <p>You're not here by accident. God loves you. He wants you to have a personal relationship with Him through Jesus, His Son. There is just one thing that separates you from God. That one thing is sin.</p>
        <p>The Bible describes sin in many ways. Most simply, sin is our failure to measure up to God’s holiness and His righteous standards. We sin by things we do, choices we make, attitudes we show, and thoughts we entertain. We also sin when we fail to do right things. The Bible affirms our own experience – “there is none righteous, not even one.” No matter how good we try to be, none of us does right things all the time.</p>
        <p>People tend to divide themselves into groups - good people and bad people. But God says that every person who has ever lived is a sinner, and that any sin separates us from God. No matter how we might classify ourselves, this includes you and me. We are all sinners.</p>
        <Bible verse="Romans 3:23" text="For all have sinned and come short of the glory of God." />
        <p>Many people are confused about the way to God. Some think they will be punished or rewarded according to how good they are. Some think they should make things right in their lives before they try to come to God. Others find it hard to understand how Jesus could love them when other people don't seem to. But I have great news for you! God DOES love you! More than you can ever imagine! And there's nothing you can do to make Him stop! Yes, our sins demand punishment - the punishment of death and separation from God. But, because of His great love, God sent His only Son Jesus to die for our sins.</p>
        <Bible verse="Romans 5:8" text="God demonstrates His own love for us in this: While we were still sinners, Christ died for us." />
        <p>For you to come to God you have to get rid of your sin problem. But, in our own strength, not one of us can do this! You can't make yourself right with God by being a better person. Only God can rescue us from our sins. He is willing to do this not because of anything you can offer Him, but JUST BECAUSE HE LOVES YOU!</p>
        <Bible verse="Titus 3:5" text="He saved us, not because of righteous things we had done, but because of His mercy." />
        <p>It's God's grace that allows you to come to Him - not your efforts to "clean up your life" or work your way to Heaven. You can't earn it. It's a free gift.</p>
        <Bible verse="Ephesians 2:8-9" text="For it is by grace you have been saved, through faith - and this not from yourselves, it is the gift of God - not by works, so that no one can boast." />
        <p>For you to come to God, the penalty for your sin must be paid. God's gift to you is His son, Jesus, who paid the debt for you when He died on the Cross.</p>
        <Bible verse="Romans 6:23" text="For the wages of sin is death, but the gift of God is eternal life in Jesus Christ our Lord." />
        <p>Jesus paid the price for your sin and mine by giving His life on a cross at a place called Calvary, just outside of the city walls of Jerusalem in ancient Israel. God brought Jesus back from the dead. He provided the way for you to have a personal relationship with Him through Jesus. When we realize how deeply our sin grieves the heart of God and how desperately we need a Savior, we are ready to receive God's offer of salvation. To admit we are sinners means turning away from our sin and selfishness and turning to follow Jesus. The Bible word for this is "repentance" - to change our thinking about how grievous sin is, so our thinking is in line with God's.</p>
        <p>All that's left for you to do is to accept the gift that Jesus is holding out for you right now.</p>
        <Bible verse="Romans 10:9-10" text="If you confess with your mouth, &quot;Jesus is Lord,&quot; and believe in your heart that God raised him from the dead, you will be saved. For it is with your heart that you believe and are justified, and it is with your mouth that you confess and are saved." />
        <p>God says that if you believe in His son, Jesus, you can live forever with Him in glory.</p>
        <Bible verse="John 3:16" text="For God so loved the world that He gave his one and only Son, that whoever believes in him shall not perish, but have eternal life." />
        <p>Are you ready to accept the gift of eternal life that Jesus is offering you right now? Let's review what this commitment involves:</p>
        <p>I acknowledge I am a sinner in need of a Savior - this is to repent or turn away from sin</p>
        <p>I believe in my heart that God raised Jesus from the dead - this is to trust that Jesus paid the full penalty for my sins</p>
        <p>I confess Jesus as my Lord and my God - this is to surrender control of my life to Jesus</p>
        <p>I receive Jesus as my Savior forever - this is to accept that God has done for me and in me what He promised</p>
        <p>If it is your sincere desire to receive Jesus into your heart as your personal Lord and Savior, then talk to God from your heart:</p>

        <h2>Here's a Suggested Prayer:</h2>
        <p>"Lord Jesus, I know that I am a sinner and I do not deserve eternal life. But, I believe You died and rose from the grave to make me a new creation and to prepare me to dwell in your presence forever. Jesus, come into my life, take control of my life, forgive my sins and save me. I am now placing my trust in You alone for my salvation and I accept your free gift of eternal life."</p>
        <p>If you have trusted Jesus as your Lord and Savior, please let us know. We want to rejoice in what God has done in your life and help you to grow spiritually.</p>

        <h2>DO I HAVE TO BE BAPTIZED?</h2>
        <p>This is one of those trick questions because the answer is yes and no.</p>
        <p>No you do not have to be baptized in water to be saved, and yes you do have to be baptized by the filling of the Holy Spirit at the moment of salvation. Water baptism, as conducted according to scripture is complete immersion of the body (Acts 8:35-37). The Greek word transliterated as baptize is baptizo and it literally means "to immerse".</p>
        <p>Baptism is an act of obedience where the believer identifies him or herself with Christ in His death, burial, and resurrection. Baptism is the first public act of obedience a believer performs and it sets the stage for a life of discipleship and submission to the will of God in a person's life.</p>
        <p>Baptism is also the method by which new believers are brought into membership with a local congregation of the Church. The book of Acts repeatedly refers to multitudes of people being "added to the church" following their submission to the gospel and baptism. It is through salvation, baptism, and church membership that a believer is entitled to partake of the Lord's Supper.</p>

        <h2>DO I HAVE TO JOIN / GO TO CHURCH?</h2>
        <p>Again this may seem to be a trick question. Yes you do need to join and attend a bible teaching, bible preaching, on fire for God, ready to labor for the Kingdom Church. But, no one is going to make you. </p>
        <p>It is difficult to imagine a true Christian who would not WANT to attend church and to join a church.</p>
        <p>Church membership is essential to connect with other like-minded believers. Matthew 28:19-20 says "19 Go therefore and make disciples of all the nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, 20 teaching them to observe all things that I have commanded you; and lo, I am with you always, even to the end of the age.” Amen." Church membership and attendance is where the work of discipleship, training, learning, and growing take place.</p>
    </Layout>
